import config from '/config';

// Data
import listingFilters from '/data/listing-filters'

const alterListingFilter = (pageType = 'category', key, value, query, remove) => {
	let filter = listingFilters[pageType][key];

	if(filter.dataType === 'array') {
		let currentVal = query[key] ? query[key].split(config.listingFilterSeperator) : [];

		if (remove) {
			const index = currentVal.indexOf(value.toString());

			if (index !== -1) {
				currentVal.splice(index, 1);
			}
			return currentVal.length ? currentVal.join(config.listingFilterSeperator) : false;
		}
		else {
			if (!currentVal.includes(value.toString())) {
				currentVal.push(value.toString());
			}

			return currentVal.join(config.listingFilterSeperator);
		}
	}
	else {
		const strVal = value.toString();

		if(query[key] && query[key].toString() === strVal && remove) {
			return false;
		}
		else if ((!query[key] || (query[key] && query[key] !== value)) && !remove) {
			return strVal;
		}
		else {
			return query[key];
		}
	}
}

export default alterListingFilter