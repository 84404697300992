import config from '/config';

// Deps
const searchGenders = [
	{
		value: 2,
		key: 'girl',
		label: 'Kız',
	},
	{
		value: 1,
		key: 'boy',
		label: 'Erkek',
	},
	{
		value: 3,
		key: 'child',
		label: 'Unisex',
	},
	{
		value: config.listingPregnantGender,
		key: 'pregnant',
		label: 'Hamile',
	}
]

export default searchGenders