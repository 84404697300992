import '/assets/styles/sections/listing.scss';
import config from '/config';

// Deps
import { useState, useEffect, useRef, Fragment, useCallback, useMemo } from 'react'
import omit from 'lodash/omit'
import Cleave from 'cleave.js/react';
import SimpleBar from 'simplebar-react'

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';
import useVisible from '@wearetla/tla-essentials-tools/hooks/visible';

// Partials
import Placeholder from '/views/partials/placeholder'
import Link from '@wearetla/tla-essentials-tools/partials/link';
import Icon from '@wearetla/tla-essentials-tools/partials/icon';
import Productbox from '/views/partials/productbox'
import Pagination from '/views/partials/pagination'
import Btn from '/views/partials/btn'
import Img from '@wearetla/tla-essentials-tools/partials/img';
import { Input } from '/views/partials/forms'
import Slider from '/views/partials/slider'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';
import sortObject from '@wearetla/tla-essentials-tools/functions/sort-object';
import alterListingFilter from '/functions/alter-listing-filter'

// Data
import listingOrderOpts from '/data/listing-order-opts'
import searchGenders from '/data/genders-search'
import ageGroups from '/data/age-groups'
import RoundBtn from '/views/partials/round-btn';

// Services
import userServices from '/services/user';

// Context
import { useBreakpoints } from "@wearetla/tla-essentials-tools/utilities/breakpoints";
import { useGlobalState } from '@wearetla/tla-essentials-tools/utilities/global-state';
import { useChildren } from '/utilities/children';

// Statics
const scrollbarOpts = {
	forceVisible: "y",
	autoHide: false,
}

const filterSortOpts = [
	{
		key: 'default',
		text: 'AZ',
		icon: 'arrow-down',
	},
	{
		key: 'asc',
		text: 'AZ',
		icon: 'arrow-down',
	},
	{
		key: 'desc',
		text: 'ZA',
		icon: 'arrow-up',
	},
]

const useFilterSelections = (allFilters, activeQuery) => {
	const calculateFilters = (filters, query) => {
		let newFilters = {};
		let filterSelected = false;

		if(filters) {
			for(const filter of filters) {
				const filterData = {
					hideFromSum: filter.hideFromSum,
					data: filter,
					key: filter.key,
					title: filter.singleTitle,
				}
				if(query[filter.key]){
					filterSelected = true;
					if(filter.type === 'age') {
						let foundOpt = filter.data.find(f => f && f.id === query[filter.key]);

						if(foundOpt) {
							newFilters[filter.key] = {
								...filterData,
								values: [
									{
										valueText: foundOpt.name,
										value: query[filter.key],
									}
								]
							}
						}
					}
					else if(filter.type === 'gender') {
						let foundOpt = filter.data.find(f => f.id.toString() === query[filter.key]);

						if(foundOpt) {
							newFilters[filter.key] = {
								...filterData,
								values: [
									{
										valueText: foundOpt.name,
										value: query[filter.key],
									}
								]
							}
						}
					}
					else if(filter.type === 'grouped') {
						let arrayFilterData = {
							...filterData,
							values: [],
						}
						for(const val of query[filter.key].split(config.listingFilterSeperator)){
							for(const group of filter.data) {
								const matchValue = group.filters.find(opt => opt.id.toString() === val);
	
								if(matchValue) {
									arrayFilterData.values.push({
										title: matchValue.name,
										valueText: matchValue.property,
										value: matchValue.id,
									});
								}
							}
						}

						newFilters[filter.key] = arrayFilterData;
					}
					else if(filter.dataType === 'array') {
						let arrayFilterData = {
							...filterData,
							values: [],
						}
						for(const val of query[filter.key].split(config.listingFilterSeperator)){
							const matchValue = filter.data.find(opt => opt.id.toString() === val);

							if(matchValue) {
								arrayFilterData.values.push({
									valueText: matchValue.name,
									value: matchValue.id,
								});
							}
						}

						newFilters[filter.key] = arrayFilterData;
						// newFilters.push(arrayFilterData)
					}
					else if(filter.dataType === 'bool'){
						newFilters[filter.key] = {
							...filterData,
							values: [
								{
									valueText: 'Evet',
									value: '1',
								}
							]
						}
					}
					else {
						// newFilters.push({
						newFilters[filter.key] = {
							...filterData,
							values: [
								{
									valueText: query[filter.key],
									value: query[filter.key],
								}
							]
						}
					}
				}
				else if (filter.type === 'range') {
					if(query[filter.minKey]) {
						filterSelected = true;
						newFilters[filter.minKey] = {
							...filterData,
							title: filter.minSingleTitle,
							values: [
								{
									valueText: `${query[filter.minKey]}${filter.unit}`,
									value: query[filter.minKey],
								}
							]
						}
					}
					if(query[filter.maxKey]) {
						filterSelected = true;
						newFilters[filter.maxKey] = {
							...filterData,
							title: filter.maxSingleTitle,
							values: [
								{
									valueText: `${query[filter.maxKey]}${filter.unit}`,
									value: query[filter.maxKey],
								}
							]
						}
					}
				}
				else {
					// newFilters.push({
					newFilters[filter.key] = {
						...filterData,
						values: [],
					};
				}
			}
		}

		return [newFilters, filterSelected];
	}

	const initialFilters = useRef(calculateFilters(allFilters, activeQuery));
	const [filters, setFilters] = useState(initialFilters.current);


	useEffect(() => {
		setFilters(calculateFilters(allFilters, activeQuery));
	}, [allFilters, activeQuery]);

	return filters;
}

const Listing = ({ title, emptyMessage, description, filters, pagination, onUpdateQuery, page, banners, suggestions, ...props }) => {
	const products = props.products ? props.products : [false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false];

	const { mobile } = useBreakpoints();
	const { isApplication } = useGlobalState();
	const { childSelection, setChildSelection } = useChildren();

	const childData = false;

	const queryBuffer = useRef(props.query);
	const propsQueryBuffer = useRef(props.query);

	const [showFiltersMenu, setShowFiltersMenu] = useState(false);
	const [showOrderMenu, setShowOrderMenu] = useState(false);

	const [query, setQueryRaw] = useState(props.query);
	const debouncedQuery = useDebounce(query);

	const [filterSelections, filterSelected] = useFilterSelections(filters, query);

	const paginationPage = query ? query.page : false;

	const setQuery = (newQuery) => {
		queryBuffer.current = sortObject(newQuery);
		setQueryRaw(queryBuffer.current);
	}

	useEffect(() => {
		propsQueryBuffer.current = props.query;
		setQuery(props.query);
	}, [props.query])

	useEffect(() => {
		if(!isExact(debouncedQuery, propsQueryBuffer.current)){
			// eslint-disable-next-line no-unused-vars
			const { primaryVariable, ...updatedQuery } = debouncedQuery;
			onUpdateQuery(updatedQuery);
		}
	}, [debouncedQuery, onUpdateQuery])

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [paginationPage])

	const clearSumFilters = (newQuery) => {
		setQuery(newQuery);
	}

	const openChildMenu = () => {
		const msgEvent = new CustomEvent('openChildMenu');
		window.dispatchEvent(msgEvent);
	}

	const clearChildSelection = () => {
		setChildSelection(false);
		let newQuery = omit(queryBuffer.current, ['genders', 'age_range']);
		setTimeout(() => {
			setQuery(newQuery);
		}, 10)
	}

	const updateFilter = (key, value) => {
		if(!((value && queryBuffer.current[key] === value) || (!value && !queryBuffer.current[key]))) {
			let newQuery = omit(queryBuffer.current, [key, 'page']);
	
			if(value) {
				newQuery[key] = value;
			}
			queryBuffer.current = newQuery;
			setQuery(newQuery);
		}
	}

	const paginate = (page) => {
		let newQuery = {
			...queryBuffer.current,
			page: page.toString(),
		}
		setQuery(newQuery);
		onUpdateQuery(newQuery);
	}
	
	return (
		<div className="section listing">
			{mobile &&
				<>
					{filters !== undefined &&
						<ListingFilters
							onClearChildSelection={clearChildSelection}
							mobile={true}
							show={showFiltersMenu}
							filters={filters}
							query={query}
							totalResults={pagination.total}
							onUpdate={updateFilter}
							onClose={() => { setShowFiltersMenu(false); }}
							filterSelections={filterSelections}
							childSelection={childSelection}
							childData={childData}
							page={page} />
					}

					<OrderMenu
						mobile={true}
						query={query}
						listingOrderOpts={listingOrderOpts}
						onOrderChange={(val) => { setQuery({ ...query, 'order': val }) }}
						onClose={() => { setShowOrderMenu(false); }}
						show={showOrderMenu} />
				</>
			}
			<div className="listing-wrap wrapper">
				{(filters !== undefined && !(mobile && isApplication)) &&
					<aside className="listing-filters">
						<header className="filters-head">
							{title ?
								<h1 className="head-title">{title}</h1>
								:
								<Placeholder className="head-title" />
							}

							{(pagination && (pagination.total || pagination.total === 0)) ?
								<p className="head-results">{pagination.total} Ürün Gösteriliyor</p>
								:
								<Placeholder className="head-results" />
							}
						</header>
						{!mobile &&
							<ListingFilters
								onClearChildSelection={clearChildSelection}
								filters={filters}
								filterSelections={filterSelections}
								childSelection={childSelection}
								childData={childData}
								query={query}
								onUpdate={updateFilter}
								page={page} />
						}
					</aside>
				}

				<section className="listing-content">
					<ListingBanners banners={banners} mobile={mobile} />
					<div className="content-results">
						{mobile &&
							<div className="results-mobilecontrols">
								<button
									type="button"
									onClick={() => { setShowOrderMenu(true); }}
									className="mobilecontrols-btn">
									<Icon name="order" />
									Sırala
								</button>
								{(!isApplication && filters) &&
									<span></span>
								}
								{filters &&
									<button
										type="button"
										onClick={() => { setShowFiltersMenu(true); }}
										className={`mobilecontrols-btn${filterSelected || (query['today_cargo'] === '1' || query['stock'] === '1') ? ' active' : ''}`}>
										<Icon name="filter" />
										Filtrele
									</button>
								}
								{isApplication &&
									<button
										type="button"
										onClick={() => { openChildMenu(); }}
										className="mobilecontrols-btn">
										<Icon name="baby" />
										Bebek Seç
									</button>
								}
							</div>
						}
						{!mobile &&
							<>
								<FilterSum
									page={page}
									query={query}
									onClear={clearSumFilters}
									data={filterSelections}
									filters={filters}
									childSelection={childSelection}
									onUpdate={updateFilter} />
								<OrderMenu
									query={query}
									listingOrderOpts={listingOrderOpts}
									onOrderChange={(val) => { setQuery({ ...query, 'order': val }) }} />
							</>
						}
						
						{products && products.length !== 0 ?
							<ul className="results-list">
								{products.map((product, nth) => (
									<Fragment key={nth}>
										{(!product || (product.slug && product.id)) &&
											<li className="list-result">
												<Productbox
													className="result-product"
													product={product} />
											</li>
										}
									</Fragment>
								))}
							</ul>
							:
							<div className={`results-emptybox${childSelection.empty ? '' : ' has-child-selection'}`}>
								<Icon className="emptybox-icon" name="frown" />
								<div className="emptybox-text">
									<strong className="text-title">Üzgünüz</strong>
									{emptyMessage}

									{!childSelection.empty &&
										<div className="text-childselection">
											<p>"Bebeğim" alanındaki aktif filtrenizden dolayı ürün görüntülenmiyor olabilir.</p>
											<p>Filtreyi kaldırarak aramayı genişletebilirsiniz.</p>

											<button
												type="button"
												onClick={clearChildSelection}
												className="childselection-clear">
												Bebek seçimini temizle
											</button>
										</div>
									}
								</div>
							</div>
						}

						{!(products && products.length === 0) &&
							<Pagination className="results-pagination" mobile={false} data={pagination} onPaginate={paginate} />
						}
					</div>
					{(description && !isApplication) &&
						<div className="listing-description wysiwyg" dangerouslySetInnerHTML={{__html: description}} />
					}
				</section>

			</div>

			{(suggestions && !(suggestions?.items.length === 0)) &&
				<ListingSuggestions data={suggestions} />
			}
		</div>
	)
}

// Order
const OrderMenu = ({ mobile, show, onOrderChange, listingOrderOpts, query, onClose }) => {
	const [inDom, visible] = useVisible(show, 300);

	useEffect(() => {
		if(inDom && mobile) {
			document.body.classList.add("block-overflow-listingordermenu");
		}
		else {
			document.body.classList.remove("block-overflow-listingordermenu");
		}

		return (() => {
			document.body.classList.remove("block-overflow-listingordermenu");
		})
	}, [inDom, mobile])

	if(!mobile) {
		return (
			<div className={`results-order${visible ? ' show' : ''}`}>
				<strong className="order-title">Sıralama</strong>
				<ul className="order-opts">
					{listingOrderOpts.map((opt) => (
						<li className="opts-opt" key={opt.value}>
							<button
								onClick={() => { onOrderChange(opt.value); }}
								className={'opt-btn' + (opt.value === query.order ? ' active' : '')}>
								{opt.label}
							</button>
						</li>
					))}
				</ul>
			</div>
		)
	}
	else if(inDom) {
		return (
			<div className={`listing-mobileorder${visible ? ' show' : ''}`}>
				<div className="mobileorder-head">
					<strong className="head-title">Sıralama</strong>

					<RoundBtn
						onClick={onClose}
						className="head-btn close" />
				</div>
				<div className="mobileorder-content">
					<ul className="content-opts">
						{listingOrderOpts.map((opt) => (
							<li className="opts-opt" key={opt.value}>
								<button
									onClick={() => { onOrderChange(opt.value); }}
									className={'opt-btn' + (opt.value === query.order ? ' active' : '')}>
									{opt.label}
								</button>
							</li>
						))}
					</ul>
				</div>

				<div className="mobileorder-ctawrap">
					<Btn
						onClick={onClose}
						className="mobileorder-cta block big primary">
						Uygula
					</Btn>
				</div>
			</div>
		)
	}
	else {
		return false;
	}
}

const useSumFilters = (filters, childSelection) => {
	const reduceFilters = useCallback((filterList) => {
		let newData = [];
		for(const key in filterList) {
			if(!filterList[key].hideFromSum && (childSelection.empty || !((key === 'gender_type' && childSelection.gender !== false) || (key === 'age_range' && childSelection.age !== false)))) {
				for(const val of filterList[key].values) {
					newData.push({
						...omit(filterList[key], ['values']),
						...val,
					});
				}
			}
		}
		return newData;
	}, [childSelection]);

	const initialFilters = useRef(reduceFilters(filters))
	const [filteredData, setFilteredData] = useState(initialFilters.current);

	useEffect(() => {
		setFilteredData(reduceFilters(filters));
	}, [filters, reduceFilters])

	return filteredData;
}

const FilterSum = ({ onUpdate, onClear, query, page, data, childSelection, ...props}) => {
	const sumFilters = useSumFilters(data, childSelection);

	const removeFilter = (filter) => {
		onUpdate(filter.key, alterListingFilter(page, filter.key, filter.value, query, true));
	}

	const clear = () => {
		let omitList = [];

		for(const filter of props.filters) {
			if (!filter.hideFromSum && (childSelection.empty || !((filter.key === 'gender_type' && childSelection.gender !== false) || (filter.key === 'age_range' && childSelection.age !== false)))) {
				if(filter.type === 'range') {
					omitList.push(filter.minKey);
					omitList.push(filter.maxKey);
				}
				else {
					omitList.push(filter.key);
				}
			}
		}

		onClear(omit(query, omitList));
	}

	if(sumFilters.length) {
		return (
			<div className="results-filtersum">
				{sumFilters.map((filter) => (
					<span className="filtersum-filter" key={filter.key + '-' + filter.value}>
						<span className="filter-text">
							<strong className="filter-title">{filter.title}</strong>
							<span className="filter-value">{filter.valueText}</span>
						</span>
						<button
							onClick={() => { removeFilter(filter) }}
							className="filter-removebtn">
							<Icon name="close" />
						</button>
					</span>
				))}
				<button className="filtersum-clear" onClick={clear}>filtreleri temizle</button>
			</div>
		)
	}
	else { return false; }
}

const ListingFilters = ({ filters, page, query, onUpdate, mobile = false, show, onClose, totalResults, filterSelections, childSelection, childData, onClearChildSelection }) => {
	const [inDom, visible] = useVisible(show, 300);
	const [activeFilter, setActiveFilter] = useState(false);

	useEffect(() => {
		if(inDom && mobile) {
			document.body.classList.add("block-overflow-listingfilters");
		}
		else {
			document.body.classList.remove("block-overflow-listingfilters");
		}

		return (() => {
			document.body.classList.remove("block-overflow-listingfilters");
		})
	}, [inDom, mobile])

	const filterChange = (key, value, check) => {
		onUpdate(key, alterListingFilter(page, key, value, query, !check))
	}

	const rangeFilterChange = (filter, start, end) => {
		onUpdate(filter.minKey, start);
		onUpdate(filter.maxKey, end);
	}

	const filterData = filters ? (filters.map((filter) => {
		const getFilterComponent = (filter) => {
			if(filter.hide === true) {
				return false;
			}
			else if(filter.type === 'link'){
				return (
					<FILTERGROUP_LINK
						mobile={mobile}
						filter={filter}
						key={filter.key}
						onSelect={() => { setActiveFilter(false); }}
						page={page} />
				)
			}
			else if(filter.type === 'range'){
				return (
					<FILTERGROUP_RANGE
						mobile={mobile}
						filter={filter}
						min={query[filter.minKey]}
						max={query[filter.maxKey]}
						onChange={(from, to) => { rangeFilterChange(filter, from, to); }}
						key={filter.key} />
				)
			}
			else if(filter.type === 'radio') {
				return (
					<FILTERGROUP_RADIO
						mobile={mobile}
						filter={filter}
						value={query[filter.key] ? query[filter.key] : ''}
						onChange={(value, check) => { filterChange(filter.key, value, check); }}
						key={filter.key} />
				)
			}
			else if(filter.type === 'age'){
				if(childSelection.empty || !childSelection.age) {
					return (
						<FILTERGROUP_DEFAULT
							mobile={mobile}
							filter={filter}
							value={query[filter.key] ? query[filter.key] : ''}
							onChange={(value, check) => { filterChange(filter.key, value, check); }}
							key={filter.key} />
					)
				}
				else {
					return false;
				}
			}
			else if(filter.type === 'gender'){
				if(childSelection.empty || !childSelection.gender) {
					return (
						<FILTERGROUP_DEFAULT
							mobile={mobile}
							filter={filter}
							value={query[filter.key] ? query[filter.key] : ''}
							onChange={(value, check) => { filterChange(filter.key, value, check); }}
							key={filter.key} />
					)
				}
				else {
					return false;
				}
			}
			else if(filter.type === 'grouped') {
				return (
					<FILTERGROUP_GROUPED
						mobile={mobile}
						filter={filter}
						value={query[filter.key] ? query[filter.key] : ''}
						onChange={(value, check) => { filterChange(filter.key, value, check); }}
						key={filter.key} />
				)
			}
			else if(filter.type === 'color') {
				return (
					<FILTERGROUP_COLOR
						mobile={mobile}
						filter={filter}
						value={query[filter.key] ? query[filter.key] : ''}
						onChange={(value, check) => { filterChange(filter.key, value, check); }}
						key={filter.key} />
				)
			}
			else {
				return (
					<FILTERGROUP_DEFAULT
						mobile={mobile}
						filter={filter}
						value={query[filter.key] ? query[filter.key] : ''}
						onChange={(value, check) => { filterChange(filter.key, value, check); }}
						key={filter.key} />
				)
			}
		}

		return {
			...filter,
			component: getFilterComponent(filter),
			selections: filterSelections ? filterSelections[filter.key] : false,
		}
	})) : [];

	if(!mobile) {
		return <>
			{(childSelection.gender !== false || childSelection.age !== false) &&
				<FILTERGROUP_CHILDSELECTIONDISPLAY
					childSelection={childSelection}
					childData={childData}
					onClear={onClearChildSelection} />
			}
			{filterData.map((filter, nth) => {
				return (<Fragment key={nth}>
					{(nth === 2 || (nth < 2 && nth === filterData.length)) &&
						<FILTERGROUP_LISTINGOPTIONS
							onChange={(key, value, check) => { filterChange(key, value, check); }}
							todayCargo={query['today_cargo']}
							inStock={query['stock']} />
					}
					{filter.component}
				</Fragment>)
			})}
		</>
	}
	else if(inDom) {
		return (
			<div className={`listing-mobilefilters${visible ? ' show' : ''}${activeFilter ? ' sub' : ''}`}>
				<div className="mobilefilters-head">
					{activeFilter ?
						<>
							<RoundBtn
								icon="angle-left"
								onClick={() => { setActiveFilter(false); }}
								className="head-btn back" />
							<div className="head-title">
								<strong>{activeFilter.title}</strong>
							</div>
						</>
						:
						<>
							<div className="head-title">
								<strong>Filtreleme</strong>

								<p className="title-total">{totalResults} ürün gösteriliyor.</p>
							</div>
							<RoundBtn
								onClick={onClose}
								className="head-btn close" />
						</>
					}
				</div>

				{activeFilter &&
					<div className="mobilefilters-content mobilefilters-filterdetail">
						{activeFilter.component}
					</div>
				}
				{(!activeFilter && filterData) &&
					<div className="mobilefilters-content mobilefilters-filters">
						{(childSelection.gender !== false || childSelection.age !== false) &&
							<FILTERGROUP_CHILDSELECTIONDISPLAY
								childSelection={childSelection}
								childData={childData}
								onClear={onClearChildSelection} />
						}
						{filterData.map((filter, nth) => (
							<Fragment key={filter.key}>
								{!((filter.type === 'gender_type' && childSelection.gender) || (filter.type === 'age_range' && childSelection.age) || filter.hide) &&
									<button
										onClick={() => { setActiveFilter(filterData[nth]); }}
										className="filters-filter"
										type="button">
										<strong className="filter-title">{filter.title}</strong>

										{filter.selections && filter.selections.values.length > 0 &&
											<p className="filter-values">
												{filter.selections.values.map((val, nth) => (
													<span key={nth}>{val.valueText}</span>
												))}
											</p>
										}
									</button>
								}
								{(nth === 1 || (nth === 0 && filterData.length === 1)) && (() => {
									let selected = [];

									if(query['today_cargo'] === '1') {
										selected.push('Aynı Gün Kargo');
									}
									if(query['stock'] === '1') {
										selected.push('Sadece Stoktakiler');
									}

									return (
										<button
											onClick={() => { setActiveFilter({
												title: 'Listeleme Seçenekleri',
												component: <>
													<FILTERGROUP_LISTINGOPTIONS
														mobile={true}
														onChange={(key, value, check) => {
															onUpdate(key, check ? value : false);
														}}
														todayCargo={query['today_cargo']}
														inStock={query['stock']} />
												</>
											}); }}
											className="filters-filter"
											type="button">
											<strong className="filter-title">Listeleme Seçenekleri</strong>
											{selected.length > 0 &&
												<p className="filter-values">
													{selected.join(', ')}
												</p>
											}
										</button>
									)
								})()}
							</Fragment>
						))}
					</div>
				}

				<div className="mobilefilters-ctawrap">
					<Btn
						onClick={() => { activeFilter ? setActiveFilter(false) : onClose(); }}
						className="mobilefilters-cta block big primary">
						{activeFilter ? 'Uygula' : 'Devam Et'}
					</Btn>
				</div>
			</div>
		)
	}
	else {
		return false;
	}
	
}

// Filter Types
const FILTERGROUP_DEFAULT = ({ filter, value, onChange, mobile }) => {
	const values = value && value.length ? value.split(config.listingFilterSeperator) : [];
	const filterData = filter.data;

	const [sortedData, setSortedData] = useState(filterData);
	const [filteredData, setFilteredData] = useState(sortedData);
	const [filterText, setFilterText] = useState('');
	const [sortOptKey, setSortOptKey] = useState(0);

	const sortOpt = filterSortOpts[sortOptKey];

	useEffect(() => {
		if(sortOpt.key === 'default') {
			setSortedData(filterData);
		}
		else {
			let newFilterData = [...filterData];

			if(sortOpt.key === 'asc') {
				newFilterData.sort(function(a, b){
					if(a.name < b.name) { return -1; }
					if(a.name > b.name) { return 1; }
					return 0;
				})
			}
			else if(sortOpt.key === 'desc') {
				newFilterData.sort(function(a, b){
					if(a.name < b.name) { return 1; }
					if(a.name > b.name) { return -1; }
					return 0;
				})
			}
			
			setSortedData(newFilterData);
		}
	}, [sortOpt, filterData])

	useEffect(() => {
		if(filterText && filterText.length > 0) {
			setFilteredData(sortedData.filter((opt) => {
				return opt.name ? opt.name.match(new RegExp(filterText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')) : false;
			}))
		}
		else {
			setFilteredData(sortedData);
		}
	}, [filterText, sortedData])

	return (
		<div className="listing-filtergroup type-default">
			{(!mobile || filter.sort) &&
				<div className="group-head">
					{!mobile &&
						<strong className="head-title">{filter.title}</strong>
					}
					{filter.sort &&
						<button
							onClick={() => {
								setSortOptKey(filterSortOpts.length > (sortOptKey + 1) ? (sortOptKey + 1) : 0)
							}}
							type="button"
							className={`head-sortbtn ${sortOpt.key}`}>
							{sortOpt.text} {sortOpt.icon && <Icon className="sortbtn-icon" name={sortOpt.icon} />}
						</button>
					}
				</div>
			}
			{filter.search &&
				<input
					className="group-searchfilter"
					type="text"
					onChange={(e) => { setFilterText(e.target.value); }}
					placeholder={filter.searchText ? filter.searchText : 'Ara'}
					value={filterText} />
			}
			<SimpleBar
				{...scrollbarOpts}
				className="group-optionswrap">
				<ul className="group-options">
					{filteredData.map((opt) => {
						const checked = opt && values.includes(opt.id.toString());
						if(checked) {
							return (
								<li className="options-option" key={opt.id}>
									<Input
										type="checkbox"
										className="option-input multi-line small"
										label={
											<>
												{ opt.name } 
												{(opt.product_count || opt.product_count === 0) &&
												<span className="input-count">({opt.product_count})</span>
												}
											</>
										}
										checked={checked}
										onChange={(e) => { onChange(opt.id, (e ? true : false)) }}
										value={opt.id}
										key={`${filter.key}-opt-${opt.id}`}
										id={`${filter.key}-opt-${opt.id}`} />
								</li>
							)
						}
						else {
							return false;
						}
					})}
					{filteredData.map((opt) => {
						const showElem = (opt && ((!filter.reduce || values.length === 0) && !values.includes(opt.id.toString())));
						if(showElem) {
							return (
								<li className="options-option" key={opt.id}>
									<Input
										type="checkbox"
										className="option-input multi-line small"
										label={
											<>
												{ opt.name } 
												{(opt.product_count || opt.product_count === 0) &&
												<span className="input-count">({opt.product_count})</span>
												}
											</>
										}
										// checked={false}
										onChange={(e) => { onChange(opt.id, (e ? true : false)) }}
										value={opt.id}
										key={`${filter.key}-opt-${opt.id}`}
										id={`${filter.key}-opt-${opt.id}`} />
								</li>
							)
						}
						else {
							return false;
						}
					})}
				</ul>
			</SimpleBar>
		</div>
	)
}

const FILTERGROUP_RADIO = ({ filter, value, onChange, mobile }) => {
	// const value = value.length ? value.split(config.listingFilterSeperator) : [];
	const filterData = filter.data;

	const [sortedData, setSortedData] = useState(filterData);
	const [filteredData, setFilteredData] = useState(sortedData);
	const [filterText, setFilterText] = useState('');
	const [sortOptKey, setSortOptKey] = useState(0);

	const sortOpt = filterSortOpts[sortOptKey];

	useEffect(() => {
		if(sortOpt.key === 'default') {
			setSortedData(filterData);
		}
		else {
			let newFilterData = [...filterData];

			if(sortOpt.key === 'asc') {
				newFilterData.sort(function(a, b){
					if(a.name < b.name) { return -1; }
					if(a.name > b.name) { return 1; }
					return 0;
				})
			}
			else if(sortOpt.key === 'desc') {
				newFilterData.sort(function(a, b){
					if(a.name < b.name) { return 1; }
					if(a.name > b.name) { return -1; }
					return 0;
				})
			}
			
			setSortedData(newFilterData);
		}
	}, [sortOpt, filterData])

	useEffect(() => {
		if(filterText && filterText.length > 0) {
			setFilteredData(sortedData.filter((opt) => {
				return opt.name ? opt.name.match(new RegExp(filterText, 'gi')) : false;
			}))
		}
		else {
			setFilteredData(sortedData);
		}
	}, [filterText, sortedData])

	return (
		<div className="listing-filtergroup type-default type-radio">
			{(!mobile || filter.sort) &&
				<div className="group-head">
					{!mobile &&
						<strong className="head-title">{filter.title}</strong>
					}
					{filter.sort &&
						<button
							onClick={() => {
								setSortOptKey(filterSortOpts.length > (sortOptKey + 1) ? (sortOptKey + 1) : 0)
							}}
							type="button"
							className={`head-sortbtn ${sortOpt.key}`}>
							{sortOpt.text} {sortOpt.icon && <Icon className="sortbtn-icon" name={sortOpt.icon}/>}
						</button>
					}
				</div>
			}
			{filter.search &&
				<input
					className="group-searchfilter"
					type="text"
					onChange={(e) => { setFilterText(e.target.value); }}
					placeholder={filter.searchText ? filter.searchText : 'Ara'}
					value={filterText} />
			}
			<SimpleBar
				{...scrollbarOpts}
				className="group-optionswrap">
				<ul className="group-options">
					{filteredData.map((opt) => {
						if(opt) {
							const checked = value === opt.id.toString();
							if(!filter.reduce || value.length === 0 || checked) {
								return (
									<li className="options-option" key={opt.id}>
										<Input
											type="checkbox"
											className="option-input multi-line small"
											label={
												<>
													{ opt.name } 
													{(opt.product_count || opt.product_count === 0) &&
													<span className="input-count">({opt.product_count})</span>
													}
												</>
											}
											checked={checked}
											onChange={(e) => { onChange(opt.id, (e ? true : false)) }}
											id={`${filter.key}-opt-${opt.id}`}
											value={opt.id.toString()} />
									</li>
								)
							}
							else {
								return false;
							}
						}
						else {
							return false;
						}
					})}
				</ul>
			</SimpleBar>
		</div>
	)
}

const FILTERGROUP_RANGE = ({ filter, min, max, onChange, mobile }) => {
	const [minVal, setMinVal] = useState(min ? min : '');
	const [maxVal, setMaxVal] = useState(max ? max : '');

	const optSelect = (opt, checked) => {
		if(checked) {
			const newStart = (!min || opt.start <= min) ? opt.start.toString() : min;
			const newEnd = (!max || opt.to <= max) ? opt.to.toString() : max;

			onChange(newStart, newEnd);

		}
	}

	const updateRange = () => {
		if(minVal && maxVal && minVal.length > 0 && maxVal.length > 0 && parseInt(minVal) > parseInt(maxVal)){
			onChange(maxVal, minVal);
		}
		else {
			onChange(minVal, maxVal);
		}
	}

	useEffect(() => {
		setMinVal(min ? min : '');
		setMaxVal(max ? max : '');
	}, [min, max])

	const updateAvailable = !(min === minVal && max === maxVal);

	return (
		<div className="listing-filtergroup type-default type-range">
			{!mobile &&
				<div className="group-head">
					<strong className="head-title">{filter.title}</strong>
				</div>
			}
			<div className="group-range" key="sortfilter-selected">
				<div className="range-inputs">
					<Cleave
						options={{
							numeral: true,
						}}
						placeholder="Min."
						className="range-input"
						type="text"
						value={minVal}
						onChange={(e) => { setMinVal(e.target.value.replace(/\D/g,'')) }} />
					<span className="range-seperator">-</span>
					<Cleave
						options={{
							numeral: true,
						}}
						placeholder="Maks."
						className="range-input"
						type="text"
						value={maxVal}
						onChange={(e) => { setMaxVal(e.target.value.replace(/\D/g,'')) }} />
					<Btn
						disabled={!updateAvailable}
						onClick={updateRange}
						className={`range-submit small narrow ${updateAvailable ? 'primary' : 'outline'}`}>
						Ara
					</Btn>
				</div>
				{(min || max) &&
					<button
						type="button"
						className="range-clear"
						onClick={() => {
							onChange(false, false);
						}}>
						temizle
					</button>
				}
			</div>
			{!(min || max) &&
				<SimpleBar
					key="sortfilter-opts"
					{...scrollbarOpts}
					className="group-optionswrap">
					<ul className="group-options">
						{filter.data.map((opt) => {
							return (
								<li className="options-option" key={`${filter.key}-opt-${opt.start}-${opt.to}`}>
									<Input
										type="checkbox"
										className="option-input multi-line small"
										label={
											<>
												{`${opt.start}-${opt.to}${filter.unit}`} 
											</>
										}
										checked={min && max && opt.start >= parseInt(min) && opt.to <= parseInt(min)}
										onChange={(e) => ( optSelect(opt, e ? true : false) )}
										value={opt.start+'-'+opt.to}
										id={`${filter.key}-opt-${opt.start}-${opt.to}`} />
								</li>
							)
						})}
					</ul>
				</SimpleBar>
			}
		</div>
	)
}

const FILTERGROUP_LINK = ({ filter, page, mobile, onSelect }) => {
	const [filterText, setFilterText] = useState('');

	const filteredData = useMemo(() => {
		if(filterText && filterText.length > 0) {
			return filter.data.filter((opt) => {
				return opt.name ? opt.name.match(new RegExp(filterText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')) : false;
			});
		}
		return filter.data;
	}, [filterText, filter.data])

	return (
		<div className="listing-filtergroup type-link">
			{!mobile &&
				<div className="group-head">
					<strong className="head-title">{filter.title}</strong>
				</div>
			}
			{filter.search &&
				<input
					className="group-searchfilter"
					type="text"
					onChange={(e) => { setFilterText(e.target.value); }}
					placeholder={filter.searchText ? filter.searchText : 'Ara'}
					value={filterText} />
			}
			<SimpleBar
				{...scrollbarOpts}
				className="group-optionswrap">
				<ul className="group-options">
					{filteredData.map((opt) => (
						<li className="options-option" key={opt.id}>
							<Link
								onClick={ onSelect }
								href={page}
								params={{ id: opt.id, slug: opt.slug }}>
								{opt.name}
							</Link>
						</li>
					))}
				</ul>
			</SimpleBar>
		</div>
	)
}

const FILTERGROUP_GROUPED = ({ filter, value, onChange, mobile }) => {
	const values = value && value.length ? value.split(config.listingFilterSeperator) : [];
	const filterData = filter.data;

	const [filteredData, setFilteredData] = useState(filterData);
	const [filterText, setFilterText] = useState('');

	useEffect(() => {
		if(filterText && filterText.length > 0) {
			setFilteredData(filterData.filter((group) => {
				return group.title ? group.title.match(new RegExp(filterText, 'gi')) : false;
			}))
		}
		else {
			setFilteredData(filterData);
		}
	}, [filterText, filterData])

	return (
		<div className="listing-filtergroup type-grouped">
			{!mobile &&
				<div className="group-head">
					<strong className="head-title">{filter.title}</strong>
				</div>
			}
			{filter.search &&
				<input
					className="group-searchfilter"
					type="text"
					onChange={(e) => { setFilterText(e.target.value); }}
					placeholder={filter.searchText ? filter.searchText : 'Ara'}
					value={filterText} />
			}
			<SimpleBar
				{...scrollbarOpts}
				className="group-optionswrap">
				<ul className="group-options">
					{filteredData.map((group, nth) => (
						<li className="options-subgroup" key={`group-${nth}`}>
							<strong className="subgroup-title">{group.title}</strong>

							<ul className="subgroup-options">
								{group.filters.map((opt) => {
									if(opt) {
										const checked = values.includes(opt.id.toString());
										const filtered = group.filters.find(f => values.includes(f.id.toString()));
										if(!(filter.reduce && filtered) || checked) {
											return (
												<li className="options-option" key={opt.id}>
													<Input
														type="checkbox"
														className="option-input multi-line small"
														label={
															<>
																{ opt.property }
															</>
														}
														checked={checked}
														onChange={(e) => { onChange(opt.id, (e ? true : false)) }}
														id={`${filter.key}-opt-${opt.id}`}
														value={opt.id} />
												</li>
											)
										}
										else {
											return false;
										}
									}
									else {
										return false;
									}
								})}
							</ul>
						</li>
					))}
				</ul>
			</SimpleBar>
		</div>
	)
}

const FILTERGROUP_COLOR = ({ filter, value, onChange, mobile }) => {
	const values = value && value.length ? value.split(config.listingFilterSeperator) : [];
	const filterData = filter.data;

	const [sortedData, setSortedData] = useState(filterData);
	const [filteredData, setFilteredData] = useState(sortedData);
	const [filterText, setFilterText] = useState('');
	const [sortOptKey, setSortOptKey] = useState(0);

	const sortOpt = filterSortOpts[sortOptKey];

	useEffect(() => {
		if(sortOpt.key === 'default') {
			setSortedData(filterData);
		}
		else {
			let newFilterData = [...filterData];

			if(sortOpt.key === 'asc') {
				newFilterData.sort(function(a, b){
					if(a.name < b.name) { return -1; }
					if(a.name > b.name) { return 1; }
					return 0;
				})
			}
			else if(sortOpt.key === 'desc') {
				newFilterData.sort(function(a, b){
					if(a.name < b.name) { return 1; }
					if(a.name > b.name) { return -1; }
					return 0;
				})
			}
			
			setSortedData(newFilterData);
		}
	}, [sortOpt, filterData])

	useEffect(() => {
		if(filterText && filterText.length > 0) {
			setFilteredData(sortedData.filter((opt) => {
				return opt.name ? opt.name.match(new RegExp(filterText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&'), 'gi')) : false;
			}))
		}
		else {
			setFilteredData(sortedData);
		}
	}, [filterText, sortedData])

	return (
		<div className="listing-filtergroup type-color">
			{(!mobile || filter.sort) &&
				<div className="group-head">
					{!mobile &&
						<strong className="head-title">{filter.title}</strong>
					}
					{filter.sort &&
						<button
							onClick={() => {
								setSortOptKey(filterSortOpts.length > (sortOptKey + 1) ? (sortOptKey + 1) : 0)
							}}
							type="button"
							className={`head-sortbtn ${sortOpt.key}`}>
							{sortOpt.text} {sortOpt.icon && <Icon className="sortbtn-icon" name={sortOpt.icon} />}
						</button>
					}
				</div>
			}
			{filter.search &&
				<input
					className="group-searchfilter"
					type="text"
					onChange={(e) => { setFilterText(e.target.value); }}
					placeholder={filter.searchText ? filter.searchText : 'Ara'}
					value={filterText} />
			}
			<SimpleBar
				{...scrollbarOpts}
				className="group-optionswrap">
				<ul className="group-options">
					{filteredData.map((opt) => {
						if(opt) {
							const checked = values.includes(opt.id.toString());
							if(!filter.reduce || values.length === 0 || checked) {
								return (
									<li className="options-option" key={opt.id}>
										<Input
											type="checkbox"
											className="option-input multi-line small"
											label={
												<>
													{ opt.name } 
													<span className="input-color" style={{ backgroundColor: `#${opt.color_group_code}` }}></span>
												</>
											}
											checked={checked}
											onChange={(e) => { onChange(opt.id, (e ? true : false)) }}
											value={opt.id}
											key={`${filter.key}-opt-${opt.id}`}
											id={`${filter.key}-opt-${opt.id}`} />
									</li>
								)
							}
							else {
								return false;
							}
						}
						else {
							return false;
						}
					})}
				</ul>
			</SimpleBar>
		</div>
	)
}

const FILTERGROUP_LISTINGOPTIONS = ({ inStock, todayCargo, onChange, mobile }) => {
	return (
		<div className="listing-filtergroup type-default">
			{!mobile &&
				<div className="group-head">
					<strong className="head-title">Listeleme Seçenekleri</strong>
				</div>
			}
			<div className="group-optionswrap">
				<ul className="group-options">
					<li className="options-option">
						<Input
							type="checkbox"
							className="option-input multi-line small"
							label="Aynı Gün Kargo"
							id="listing-filters-opt-todaycargo"
							checked={todayCargo === '1'}
							defaultValue="1"
							onChange={(e) => { onChange('today_cargo', '1', (e ? true : false)) }}
							// value={opt.id}
							// key={`${filter.key}-opt-${opt.id}`}
							// id={`${filter.key}-opt-${opt.id}`} />
							/>
					</li>
					<li className="options-option">
						<Input
							type="checkbox"
							className="option-input multi-line small"
							label="Sadece Stoktakiler"
							id="listing-filters-opt-instock"
							checked={inStock === '1'}
							defaultValue="1"
							onChange={(e) => { onChange('stock', '1', (e ? true : false)) }}
							// checked={checked}
							// onChange={(e) => { onChange(opt.id, (e ? true : false)) }}
							// value={opt.id}
							// key={`${filter.key}-opt-${opt.id}`}
							// id={`${filter.key}-opt-${opt.id}`} />
							/>
					</li>
				</ul>
			</div>
		</div>
	)
}

// Child Selection Sum
const useChildSelection = (childSelection, childData) => {
	const computeData = (childSelection, childData) => {
		const child = childData && childSelection.id ? childData.find(c => c.id === childSelection.id) : false;

		const genderInfo = searchGenders.find(g => g.value === childSelection.gender);
		const ageInfo = ageGroups.find(g => g.value === childSelection.age);
		
		return {
			...childSelection,
			child: child,
			genderInfo: genderInfo ? genderInfo : { key: false, },
			ageInfo: ageInfo ? ageInfo : false,
		};
	}

	const initialData = useRef(computeData(childSelection, childData));
	const [selectionData, setSelectionData] = useState(initialData.current);

	useEffect(() => {
		initialData.current = false;

		setSelectionData(computeData(childSelection, childData));
	}, [childSelection, childData]);

	return selectionData;
}

const FILTERGROUP_CHILDSELECTIONDISPLAY = ({ childSelection, childData, onClear }) => {
	const selectionData = useChildSelection(childSelection, childData);

	return (
		<div className={`listing-filtergroup type-age-display gender-${selectionData.genderInfo.key}`}>
			<strong className="display-title">Çocuğunuzun Yaşına ve Cinsiyetine Göre Ürünler Gösteriliyor</strong>

			<div className="display-content">
				{selectionData.genderInfo.key &&
					<Icon className="content-icon" name={selectionData.genderInfo.key} />
				}
				<div>
					{selectionData.child && 
						<strong className="display-childname">{selectionData.child.name}</strong>
					}

					<p className="display-features">
						{selectionData.genderInfo && selectionData.genderInfo.key &&
							<span>{selectionData.genderInfo.label}</span>
						}
						{selectionData.ageInfo &&
							<span>{selectionData.ageInfo.label}</span>
						}
					</p>
				</div>
			</div>

			<button
				type="button"
				onClick={onClear}
				className="display-clearbtn">
				Seçimi temizle
			</button>
		</div>
	)
}

// Banners
const ListingBanners = ({banners, mobile}) => {
	if(banners && banners.length > 0) {
		if(!mobile) {
			return (
				<div className="content-banners">
					{banners.map((banner) => {
						const WrapElem = banner.link && (!banner.action_text || banner.type !== 8 || !banner.title) ? Link : 'div';
						return (
							<WrapElem
								key={banner.id}
								raw={WrapElem === 'div' ? undefined : true}
								href={WrapElem === 'div' ? undefined : banner.link}
								className={`banners-banner${banner.type === 8 ? ' big' : ''}`}>
								<div className="banner-contentwrap">
									{!!(banner.title || banner.sub_title || (banner.link && banner.action_text)) &&
										<div className="banner-textcontent">
											{banner.type === 8 ?
												<>
													{!!banner.sub_title &&
														<span className="textcontent-label label big pink">{banner.sub_title}</span>
													}
													{!!banner.title &&
														<strong className="textcontent-title">{banner.title}</strong>
													}
													{!!(banner.link && banner.action_text) &&
														<Btn
															raw
															tag="link"
															href={banner.link}
															className="textcontent-cta text big pink">
															{banner.action_text}
														</Btn>
													}
												</>
												:
												<>
													{!!banner.sub_title &&
														<span className="textcontent-pretitle">
															{banner.sub_title}
														</span>
													}
													{!!banner.title &&
														<strong className="textcontent-title">
															{banner.title}
														</strong>
													}
													<Icon className="textcontent-arrow" name="arrow-right" />
												</>
											}
										</div>
									}
								</div>
								<div className="banner-imagewrap">
									<Img
										className="banner-image"
										cover
										loading={banner.type === 8 ? 'eager' : 'undefined'}
										src={banner.image_url} />
								</div>
							</WrapElem>
						)
					})}
				</div>
			)
		}
		else {
			return (
				<div className="content-banners">
					<Slider
						className="banners-slider"
						params={{
							loopAdditionalSlides: 1,
							loop: true,
						}}>
						{banners.map((banner) => {
							// const WrapElem = banner.link && (!banner.action_text || banner.type !== 8 || !banner.title) ? Link : 'div';
							return (
								<Link
									raw
									key={banner.id}
									href={banner.link}
									className="banners-banner">
									<div className="banner-contentwrap">
										{banner.title &&
											<div className="banner-textcontent">
												<>
													{banner.sub_title &&
														<span className="textcontent-pretitle">
															{banner.sub_title}
														</span>
													}
													<strong className="textcontent-title">
														{banner.title}
													</strong>
													<Icon className="textcontent-arrow" name="arrow-right" />
												</>
											</div>
										}
									</div>
									<div className="banner-imagewrap">
										<Img className="banner-image" cover src={banner.image_url} />
									</div>
								</Link>
							)
						})}
					</Slider>
				</div>
			)
		}
	}
	else {
		return false;
	}
}

// Suggestions
const ListingSuggestions = ({ data }) => {
	const suggestions = data.items ? data.items : [false, false, false, false, false, false];

	return (
		<section className="listing-suggestions wrapper">
			{data.title &&
				<h2 className="suggestions-title">{data.title}</h2>
			}

			<ul className="suggestions-list">
				{suggestions.map((suggestion, nth) => (
					<li className="list-item" key={nth}>
						{suggestion ?
							<span className="item-link">
								{suggestion.name}
							</span>
							:
							<Placeholder className="item-link" />
						}
					</li>
				))}
			</ul>
		</section>
	)
}

Listing.defaultProps = {
	title: false,
	emptyMessage: 'Aradığınız kriterlere sahip bir ürün bulunamadı.'
}

export default Listing;