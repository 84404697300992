import { useState, useEffect, useCallback, useRef } from 'react'

// Functions
import isExact from '@wearetla/tla-essentials-tools/functions/is-exact';
import parseQuery from '/functions/parse-query'

// Hooks
import useDebounce from '@wearetla/tla-essentials-tools/hooks/debounce';

const useQuery = (queryStringProp, defaultQuery, filterOpenExclusions, forbiddenKeys = [], childSelection, primaryVariable) => {
	const exclusionList = useRef(filterOpenExclusions);
	const forbiddenKeysList = useRef(forbiddenKeys);

	const calculateQuery = useCallback(parseQuery, [defaultQuery, exclusionList, forbiddenKeysList]);

	const [queryData, setQueryData] = useState(calculateQuery(queryStringProp, {...defaultQuery, primaryVariable: primaryVariable}, exclusionList.current, forbiddenKeys.current, childSelection));

	const debouncedQueryData = useDebounce(queryData);

	useEffect(() => {
		let newQuery = calculateQuery(queryStringProp, {...defaultQuery, primaryVariable: primaryVariable}, exclusionList.current, forbiddenKeys.current, childSelection);
		
		if (!isExact(newQuery, queryData)) {
			setQueryData(newQuery);
		}

	}, [queryStringProp, calculateQuery, queryData, childSelection, defaultQuery, forbiddenKeys, primaryVariable])

	return debouncedQueryData;
}

export default useQuery;