import config from '/config';

// Deps
import omit from 'lodash/omit'

// Data
import ageGroups from '/data/age-groups'

const parseQuery = (queryString, defaultQuery = {}, exclusionList = [], forbiddenKeys = [], childSelection) => {
	let paramQuery = {};
	if (queryString) {
		let filtersArray = queryString.split('&');

		for (const newFilter of filtersArray) {
			let filterArray = newFilter.split('=');
			
			if(filterArray.length === 2 && !forbiddenKeys.includes(filterArray[0])) {
				paramQuery[filterArray[0]] = decodeURIComponent(filterArray[1]);
			}
			// if (filterArray.length === 2) {
			// 	paramQuery[filterArray[0]] = decodeURIComponent(filterArray[1]);
			// }
		}
	}

	let query = {
		...defaultQuery,
		...paramQuery,
	}

	if(childSelection) {
		if(childSelection.gender){
			if(childSelection.gender === config.listingPregnantGender){
				query.age_range = config.listingPregnantValue;
			}
			else {
				query.gender_type = childSelection.gender.toString();
			}
		}
		if(childSelection.age){
			const group = ageGroups.find((g => g.value === childSelection.age));
			if(group) {
				query.age_range = group.searchValue;
			}
		}
	}

	let urlQuery = { ...query };

	for (const key in query) {
		if ((defaultQuery[key] === '' && urlQuery[key] === '') || (defaultQuery[key] === urlQuery[key])) {
			delete urlQuery[key];
		}
	}

	const hasCustomUrlParams = (Object.keys(omit(urlQuery, exclusionList)).length > 0);

	return [query, urlQuery, hasCustomUrlParams];
}

export default parseQuery;