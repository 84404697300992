const listingOrderOpts = [
	{
		value: 'ovd',
		label: 'Akıllı sıralama',
	},
	{
		value: 'odrd',
		label: 'İndirimdekiler',
	},
	{
		value: 'opa',
		label: 'En düşük fiyat',
	},
	{
		value: 'opd',
		label: 'En yüksek fiyat',
	},
	{
		value: 'ocd',
		label: 'En yeniler',
	},
	{
		value: 'ocssd',
		label: 'En yüksek puan',
	},
	{
		value: 'occd',
		label: 'En çok yorum alan',
	},
]

export default listingOrderOpts